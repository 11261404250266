<template>
  <div style="min-height: 100vh;">
    <div class="container">

      <div class="timeline-wrapper" v-show="is_busy">

        <div class="timeline-item">

          <!-- <Waiting /> -->

        </div>

      </div>

    </div>

    <Competition
        v-show="!highlights"
        v-for="(l,index) in competitions"
        v-bind:key="getLeagueKey(l,index)"
        v-bind:competition="l"
        v-bind:is_visible="true"
        v-bind:upcoming="upcoming"
        v-bind:today="today"
        v-bind:esport="esport"
        v-bind:highlights="highlights"
        v-bind:live="live"
        v-bind:leo="leo"
        v-bind:date="date">
    </Competition>

  </div>
</template>

<script>
// const Highlight = () => import("./Highlight.vue");
import Competition from './Competition'
// import Waiting from "@/components/Waiting.vue";

//import infiniteScroll from 'vue-infinite-scroll'
import axios from "@/services/api";
import mqtt from "mqtt/dist/mqtt";
import fix from "@/services/fix";

//Vue.use(infiniteScroll)

export default {
  name: "Alllive",
  mounted: function () {
    // this.fetchFixtures();
    this.reloadProfile();
    this.getBonus();
    var vm = this;

    this.is_busy = true;
    vm.visible_leagues = [];

    // keep a list of openned/expanded leagues
    this.EventBus.$on("event:leagues:show", function (id) {
      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {
        if (parseInt(v) === parseInt(id)) {
          exists = true;
        }
      });

      if (!exists) {
        vm.visible_leagues.push(parseInt(id));
      }
    });

    this.initMqtt(this.sport_id);

    this.$nextTick(function () {
      vm.autoRefreshUI(this.$vnode.tag);
    });

    //setInterval(function (){

    if (vm.searchable) {
      if (vm.search.length > 0) {
        vm.getSearch();
      } else {
        vm.is_busy = false;
      }
    } else if (vm.live) {
      vm.getAlllive();
    } else if (vm.esport) {
      vm.getEsport();
    } else if (vm.highlights) {
      vm.getHighlight();
    } else if (vm.today) {
      vm.getTodayFixtures();
    } else if (vm.leo) {
      vm.getAlllive();
    } else {
      vm.getCompetitions();
    }

    //},1000 * 30);
  },
  methods: {
    registerPoll: function () {
      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id == 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop =
          "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;

      if (this.live) {
        topic_betstop =
            "topic/producer-1/sport-" +
            sport_id +
            "/status/+/market-" +
            market_id;
      }

      this.poll(topic_betstop);
    },

    initMqtt: function () {
      var vm = this;

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: "uiuser",
        password: "prduser",
      };

      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      var topic_match = "topic/producer-3/sport-" + sport_id + "/match/+";

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_market =
          "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;
      var topic_betstop = "topic/producer-3/sport-" + sport_id + "/betstop/+";

      if (this.live) {
        var mkidString = `
858 363 638 614 1069 14 948 88 994 1017 66 303 156 756 920 1063 430 251 863 1016
1008 663 1024 649 854 432 507 598 358 235 223 284 291 317 839 203 377 821 105
860 1041 652 656 180 173 1081 446 98 565 87 112 529 1088 959 1056 475 204 488
607 1074 100 604 276 385 423 190 175 726 838 944 574 179 1083 653 332 128 438
311 581 1009 723 406 989 80 746 1047 662 431 215 214 611 894 629 152 447 283
373 421 181 27 57 1001 584 250 457 429 885 935 209 733 1050 341 592 401 198
956 220 26 104 711 419 153 1023 660 265 563 981 1073 289 36 965 589 183 544
995 974 367 648 74 508 393 599 949 202 853 639 129 368 950 481 595 728 1048
318 591 647 269 619 737 76 224 91 697 886 160 86 1076 655 231 347 964 626
331 588 492 210 1 836 157 229 556 749 7 462 389 19 971 740 64 628 621 816
855 862 641 928 120 316 700 704 1021 161 124 404 736 253 1055 258 380 29 706
48 934 178 693 482 148 237 379 976 540 877 751 261 486 694 712 216 942 174
177 285 1046 1090 230 1002 567 520 1080 896 278 375 603 515 827 1000 936
1015 1051 256 490 5 434 1064 665 448 703 729 744 815 988 372 11 727 757 802
549 411 798 189 234 596 734 993 1065 343 1036 219 106 659 351 597 94 905 799
528 571 1089 825 606 1072 865 176 413 407 616 118 1079 201 314 724 921 927
883 396 159 279 300 232 671 391 952 252 155 62 593 324 427 453 275 966 1037
583 880 841 884 456 1086 236 144 818 117 162 605 664 97 254 615 274 184 937
78 1062 745 575 840 979 879 353 458 750 514 998 658 65 543 1043 376 506 58
412 953 657 55 1042 972 172 217 566 357 587 39 8 13 154 146 222 381 558 992
803 1045 30 315 542 911 978 951 590 612 85 505 897 1029 257 957 260 642 79
107 500 452 426 414 943 891 433 390 846 909 125 245 985 137 502 980 474 824
552 115 425 1030 32 512 350 109 847 16 227 53 302 194 108 975 378 946 496
579 939 402 398 212 294 1057 478 1014 75 817 277 630 709 578 38 49 743 875
553 56 622 845 101 92 822 207 37 424 895 121 349 213 623 568 297 69 941 644
228 926 116 477 691 442 919 661 480 525 738 123 1077 187 680 287 489 541 9
77 554 443 1078 1087
`;

// Split the string into an array using spaces as the delimiter
        var mkidArray = mkidString.split(/\s+/).map(Number);

        var topicArrays = [];


        for (let mid of mkidArray) {
          topicArrays.push("topic/producer-1/+/status/+/market-" + mid);
        }
        for (let mid of mkidArray) {
          topicArrays.push("topic/producer-4/+/status/+/market-" + mid);
        }

        topic_betstop = "topic/producer-1/sport-" + sport_id + "/betstop/+";
        topic_betstop = "topic/producer-4/sport-" + sport_id + "/betstop/+";
        topic_match = 'topic/producer-1/sport-' + sport_id + '/status/+';
        //topic_producer = 'topic/producer-1';
      }

      let endpoint = "wss://ws-v2.ponyoka.com/mqtt";

      var client = mqtt.connect(endpoint, options);

      client.on("connect", function () {
        client.subscribe(topic_betstop, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_betstop);
          }
        });

        client.subscribe(topic_market, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_market);
          }
        });

        client.subscribe(topic_match, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_match);
          }
        });
        client.subscribe(topicArrays, function (err) {
          if (!err) {
            // console.log("subscribed to topicArrays " + topicArrays);
          }
        });

        var topic2 = "topic/producer";
        client.subscribe(topic2, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic2);
          }
        });
      });

      client.on("message", function (topic, msg) {

        var payload = JSON.parse(msg.toString(), undefined, 2);
        // console.log("Here is the payload:::::::::::", JSON.stringify(payload, undefined, 2));
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },

    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
          currentFixture === undefined || currentFixture.match_id == undefined
              ? index
              : currentFixture.match_id;
      return Math.random()
          .toString(10)
          .replace("0.", "fixture-id-" + prefix + "-");
    },

    getLeagueKey: function (league, index) {
      var prefix =
          league === undefined || league.competition_id == undefined
              ? index
              : league.competition_id;
      return Math.random()
          .toString(10)
          .replace("0.", "competition-id-" + prefix + "-");
    },

    getCompetitions: function () {
      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {
        endpoint = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      }

      if (this.esport) {
        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;
      }

      var path = endpoint.replace(
          "{sport_id}",
          parseInt(vm.sport_id) > 0 ? vm.sport_id : 1
      );
      path = path.replace("{count}", 0);

      var games_filter = {};

      if (this.date.length > 0) {
        games_filter.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        games_filter.upcoming = this.upcoming;
        games_filter.highlights = "";
      }

      games_filter.hour = vm.hour; //default -1

      fix
          .post(path, JSON.stringify(games_filter))
          .then((res) => {
            var leagues = res.data.message;

            if (vm.highlight || vm.highlight) {
              vm.jQuery.each(leagues, function (k, v) {
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;

                var exist = false;

                // check if league exists in current leagues
                vm.jQuery.each(vm.leagues, function (kk, vv) {
                  if (vv.competition_id === v.competition_id) {
                    exist = true;
                  }
                });

                if (!exist) {
                  vm.leagues.push(v);
                }
              });
            } else {
              vm.leagues = leagues;
              vm.jQuery.each(vm.leagues, function (k, v) {
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;
                vm.leagues[k] = v;
              });
            }

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.getGames();
            vm.autoRefreshUI(vm.$vnode.tag);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getFixture: function (competitions) {
      var vm = this;

      var path = process.env.VUE_APP_URL_FIXTURES_COMPETITIONS;

      if (this.highlights) {
        path = process.env.VUE_APP_URL_HIGHLIGHTS;
      } else if (this.today) {
        path = process.env.VUE_APP_URL_TODAYS_COMPETITIONS;
      } else if (this.live) {
        path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      }

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{competition_id}", 0);
      path = path.replace("{market_id}", vm.market_id);
      path = path.replace("{page}", 1);
      var payload = {
        highlights: 1,
        upcoming: 0,
        hours: this.hour,
        country_code: "",
        date: "",
        keyword: "",
        per_page: 100,
        competitions: competitions,
      };

      if (this.date.length > 0) {
        payload.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        payload.upcoming = this.upcoming;
        payload.highlights = "";
      }
      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = res.data.message;

            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });
            vm.competitions = vm.leagues;
            vm.autoRefreshUI(vm.$vnode.tag);
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getTodayFixtures: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';

      path = path.replace("{sport_id}", this.sport);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            // console.log("Res", res)
            var games = res.data.data;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });
            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;

            vm.getCompetitions();
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getAlllive: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      path = path.replace("{sport_id}", this.sport);
      console.log('path', path);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            console.log("Live games", res)
            var games = res.data.data;
            vm.EventBus.$emit("total-games", res.data.total);
            vm.totals = res.data.total;

            // Transform data to desired format
            var formattedData = {
              status: 200,
              message: {
                data: [],
                from: games.from,
                last_page: games.last_page,
                remaining_records: games.remaining_records,
              }
            };

            // Loop through games and create new objects with required fields
            vm.jQuery.each(games, function (k, v) {
              var formattedGame = {
                tournament: v.tournament,
                sport_id: v.sport_id,
                game_id: v.game_id,
                name: v.name,
                match_id: v.match_id,
                date: v.date,
                total_markets: 0, // Placeholder value
                ActiveMarkets: 0, // Placeholder value
                competition_id: v.tournament_id ? v.tournament_id : "",
                status_code: v.status_code,
                status: v.status,
                live_coverage: 0, // Placeholder value
                market_name: v.highlight_market.market_name,
                country: v.country,
                country_code: "",
                event_time: v.event_time ? v.event_time : "",
                home_score: v.home_score,
                match_status: v.match_status,
                event_status: v.match_status === 'Not started' ? 'NotStarted' : '',
                away_score: v.away_score,
                home_team: v.home_team,
                away_team: v.away_team,
                outcomes: [],
              };

              // Process outcomes
              vm.jQuery.each(v.highlight_market.outcomes, function (kk, vv) {
                formattedGame.outcomes.push({
                  alias: vv.alias,
                  market_name: formattedGame.market_name,
                  outcome_name: vv.outcome_name,
                  specifier: vv.specifier,
                  outcome_id: vv.outcome_id,
                  odd: vv.odds,
                  odds: vv.odds,
                  previous_odds: vv.previous_odds,
                  odd_id: vv.odd_id,
                  direction: vv.direction,
                  status: vv.status,
                  active: vv.active,
                  producer_id: v.producer_id,
                  producer_status: v.producer_status,
                  market_id: v.highlight_market.market_id,
                });
              });

              formattedData.message.data.push(formattedGame);
            });

            // console.log("Response Live >>>" + JSON.stringify(formattedData.message.data))
            vm.competitions = formattedData.message.data;

            vm.$store.dispatch("setLeague", formattedData);
            vm.is_busy = false;
            vm.visible_leagues = [];
          })
          .catch((err) => {
            // console.log("Problem", err)
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getSearch: function () {
      var vm = this;
      vm.leagues = [];
      vm.competitions = [];

      var path = process.env.VUE_APP_URL_SEARCH;

      path = path.replace("{sport_id}", 1);

      axios
          .post(path, JSON.stringify({search: vm.search}))
          .then((res) => {
            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getEsport: function () {
      var vm = this;

      var path = process.env.VUE_APP_URL_ESPORT_SPORT;

      path = path.replace("{sport_id}", 1);

      axios
          .post(path, JSON.stringify({}))
          .then((res) => {
            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;

            vm.getCompetitions();
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getLive: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      path = path.replace("{sport_id}", this.sport);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            console.log("live data sports", res)
            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;

            vm.getCompetitions();
          })
          .catch((err) => {
            // console.log("This is why live games", err)
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
              // console.log(JSON.stringify(err.response.data.message));
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getGames: function () {
      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {
        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {
          competitions.push(competition_id);
        }
      });

      vm.getFixture(competitions.join(","));
    },

    reloadUI: function () {
      this.autoRefreshUI(this.$vnode.tag);
    },
  },
  computed: {
    loadingDistance: function () {
      return 50;
    },
    market_outcome: function () {
      return this.market_outcome_headers;
    },
    games: function () {
      return this.$store.state.games;
    },
    allLoaded: function () {
      return (
          this.$store.state.games.current_page > 0 &&
          this.$store.state.games.current_page ===
          this.$store.state.games.last_page
      );
    },
    busy1: function () {
      return this.$store.state.busy;
    },
    sport_id: function () {

      return this.$store.state.sport_id;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    profile: function () {
      return this.getProfile();
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
  },
  components: {
    // Highlight,
    Competition,
    // Waiting
  },
  data: function () {
    return {
      leagues: [],
      competitions: [],
      highlightsData: [],
      loading: [],
      visible: [],
      busy: [],
      is_busy: false,
      producerId: [],
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      fixtures: [],
      totals: 0
    };
  },
  props: {
    date: {
      required: false,
      default: "",
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },
    upcoming: {
      required: false,
      default: false,
    },
    highlights: {
      required: false,
      default: false,
    },
    live: {
      required: false,
      default: true,
    },
    today: {
      required: false,
      default: false,
    },
    leo: {
      required: false,
      default: true,
    },
    sport: {
      required: false,
      default: -1,
    },
    esport: {
      required: false,
      default: false,
    },
  },
  watch: {
    sport_id: function (newValue, oldValue) {
      /*console.log(
          "sport_id newValue ==> " + newValue + " oldValue ==> " + oldValue
      );*/

      if (parseInt(oldValue) > 0) {
        this.is_busy = true;
        if (this.highlights) {
          this.getHighlight();
        } else {
          this.competitions = [];
          //this.getCompetitions();
          this.getAlllive();
        }

        // this.initMqtt(this.sport_id());
      }
    },
    market_id: function () {
      this.competitions = [];
      this.is_busy = true;
      this.initMqtt();

      if (this.highlights) {
        this.getHighlight();
      } else {
        if (this.visible_leagues.length > 0) {
          this.getFixture(this.visible_leagues.join(","));
        }
      }
    },
    hour: function (newValue, oldValue) {
      console.log("Hour =>newValue ==> " + newValue + " oldValue ==> " + oldValue);
      this.is_busy = true;
      this.getCompetitions();
    },
    search: function (newValue, oldValue) {
      console.log(
          "search =>newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      if (newValue !== oldValue && newValue.length > 0) {
        this.is_busy = true;
        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {
      console.log(
          "date newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      //if(parseInt(newValue) > 0 ) {
      this.is_busy = true;
      this.getCompetitions();
      this.initMqtt();

      //}
    },
  },
  beforeDestroy: function () {
    // console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>